module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"adamcbowen.com","short_name":"adamcbowen","start_url":"/","background_color":"#F5A","theme_color":"#F5A","display":"minimal-ui","icon":"src/images/ab-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
